@import url('https://fonts.googleapis.com/css?family=Oswald|Open+Sans|Poppins|Roboto:300,400,500,700,900,1000|Secular+One');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Search-bar{
  background: #FFFFFF;
  border-radius: 3px;
  color: white;
}

.Box-showcase::-webkit-scrollbar {
  background: transparent; 
  width: 0px;
}

.Box-showcase{
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.Article-text > p{
  font-weight: 100;
  font-style: normal;
}

@media screen and (max-width: 600px) {
  .react-pdf__Page__canvas{
    width: 100% !important;
    height: 80% !important;
  }
}

.Outlined-button{
  background-color: #285A5B; 
  border-radius: 4px;
  padding-top:1em;
  padding-bottom: 1em;
  padding-left: 1.2em;
  padding-right: 1.2em;
  color: #F4B74B; 
  border: 2px solid #F4B74B;
}

.Outlined-button:hover{
  background-color: #F4B74B; 
  border-radius: 4px;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1.2em;
  padding-right: 1.2em;
  color: #285A5B; 
  border: 2px solid #F4B74B;
}

.Title-today-special{
  font-size: 2em;
  font-weight:bolder;
  color: #FFFFFF;
  /* color: #444343; */
  text-transform: uppercase;
}

/* article */
.highlight-img{
  width: 100%;
  height: 40vh;
  object-fit: cover;
  position: relative;
  z-index: 1;
}

.item-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail{
  width:80%;
  height: 400px;
  object-fit: cover;
}

.title-showcase{
  /* width: 80%; */
  /* width: 200px; */
  max-width: 100%;
  font-size: .7em;
  font-weight: 900;
  position: absolute;
  color: white;
  background-color: #282c3463;
  padding-left: .2em;
  padding-right: .2em;
  top: 0;
}

.article-hover:hover{
  cursor: pointer;
}

@media screen and (max-width: 600px){
  .highlight-img{
    width: 100%;
    height: 20vh;
    object-fit: cover;
    position: relative;
    z-index: 1;
  }
  .thumbnail{
    width:100%;
    height: 200px;
    object-fit: cover;
  }
}

